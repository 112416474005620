import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/AdminNavbar.css';
import '../../style/Admin/AdminSideBar.css';
import '../../style/Admin/Offres.css';
import { FaSearch } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import ReactPaginate from 'react-paginate';

const Offres = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [jobOffers, setJobOffers] = useState([]);
    const [filteredOffers, setFilteredOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const offersPerPage = 6; // Number of rows to show per page

    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobOffers = async () => {
            try {
                setLoading(true);
                const response = await fetch('https://jetagroupe.com/api/job-offers');
                const data = await response.json();
                if (Array.isArray(data)) {
                    setJobOffers(data);
                    setFilteredOffers(data); // Initialize filteredOffers with array
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des offres:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobOffers();
    }, []);

    const filterOffers = debounce((searchTerm) => {
        const filteredOffers = jobOffers.filter((offer) =>
            offer.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOffers(filteredOffers);
    }, 100);

    useEffect(() => {
        filterOffers(searchTerm);
    }, [searchTerm, jobOffers, filterOffers]);

    const deleteJobOffer = async (id) => {
        try {
            const response = await fetch(`https://jetagroupe.com/api/job-offers/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setJobOffers(jobOffers.filter((offer) => offer._id !== id));
                setFilteredOffers(filteredOffers.filter((offer) => offer._id !== id));
            } else {
                console.error('Erreur lors de la suppression de l\'offre');
            }
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'offre:', error);
        }
    };

    // Pagination
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const offset = currentPage * offersPerPage;
    const currentPageOffers = filteredOffers.slice(offset, offset + offersPerPage);

    return (
        <div className="offres-container">
            <AdminNavbar />
            <AdminSidebar />
            <div className="offres-content">
                <div className="offres-header">
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Chercher un poste ..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button className="search-button">
                            <FaSearch />
                        </button>
                    </div>
                    <button 
                        className="add-offer-button" 
                        onClick={() => navigate('/AjouterOffre')} 
                    >
                        Ajouter une offre
                    </button>
                </div>
                {loading ? (
                    <div className="loading-indicator">Chargement...</div>
                ) : (
                    <>
                        <table className="offres-table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nom du poste</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(Array.isArray(currentPageOffers) ? currentPageOffers : []).map((offer, index) => (
                                    <tr key={offer._id}>
                                        <td>{index + 1 + offset}</td>
                                        <td>{offer.jobTitle}</td>
                                        <td>
                                            <button 
                                                className="voir-plus-button" 
                                                onClick={() => navigate(`/postulations/${offer._id}`)}>
                                                Voir les postulations
                                            </button>
                                            <button 
                                                className="delete-button" 
                                                onClick={() => deleteJobOffer(offer._id)}>
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={'Précédent'}
                            nextLabel={'Suivant'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(filteredOffers.length / offersPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination1'}
                            activeClassName={'active'}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Offres;
