import React, { useState } from 'react';
import '../style/Contact.css';  

function Contact() {
  const [form, setForm] = useState({
    nom: '',
    prenom: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({}); // Pour stocker les messages d'erreur

  const validateNom = (nom) => /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(nom);
  const validatePrenom = (prenom) => /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(prenom);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validateMessage = (message) => message.trim().length > 0 && message.trim().length <= 500;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!validateNom(form.nom)) {
      newErrors.nom = 'Le nom ne doit contenir que des lettres et des espaces.';
    }

    if (!validatePrenom(form.prenom)) {
      newErrors.prenom = 'Le prénom ne doit contenir que des lettres et des espaces.';
    }

    if (!validateEmail(form.email)) {
      newErrors.email = 'Veuillez entrer un email valide.';
    }

    if (!validateMessage(form.message)) {
      newErrors.message = 'Le message ne doit pas être vide et doit comporter moins de 500 caractères.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await fetch('https://jetagroupe.com/api/contact/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(form)
      });
      if (response.ok) {
        alert('Message envoyé avec succès');
        setForm({
          nom: '',
          prenom: '',
          email: '',
          message: ''
        });
        setErrors({});
      } else {
        alert('Échec de l\'envoi du message');
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  return (
    <section id="contact">
      <div className="contact-container">
        <h2 className="contact-title">Contactez-nous</h2>
        <div className="contact-form-container">
          <div className="contact-form-left">
            <h3 className="contact-form-left-title1">Nous sommes impatients de collaborer avec vous et de transformer vos idées en solutions innovantes et efficaces.</h3>
            <div className="contact-form-left-text">
              <p>Contactez-nous dès aujourd'hui pour discuter de vos projets et découvrir comment nous pouvons vous aider à atteindre vos objectifs.</p>
            </div>
          </div>
          <div className="contact-form-right">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nom</label>
                <input 
                  type="text" 
                  name="nom" 
                  value={form.nom} 
                  onChange={handleChange} 
                />
                {errors.nom && <p className="error-message">{errors.nom}</p>}
              </div>
              <div className="form-group">
                <label>Prénom</label>
                <input 
                  type="text" 
                  name="prenom" 
                  value={form.prenom} 
                  onChange={handleChange} 
                />
                {errors.prenom && <p className="error-message">{errors.prenom}</p>}
              </div>
              <div className="form-group">
                <label>Email</label>
                <input 
                  type="email" 
                  name="email" 
                  value={form.email} 
                  onChange={handleChange} 
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea 
                  name="message" 
                  value={form.message} 
                  onChange={handleChange} 
                ></textarea>
                {errors.message && <p className="error-message">{errors.message}</p>}
              </div>
              <button type="submit" className="submit-button-contact">Envoyer</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
