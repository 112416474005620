import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/Navbar.css';
import { FaBars, FaTimes, FaPhoneAlt } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";

import logo from '../assets/images/logo1.png';

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} alt="JETA GROUPE" />
        </div>
        <div className="navbar-links">
          <Link to="/" onClick={() => scrollToSection('accueil')}>Accueil</Link>
          <Link to="/" onClick={() => scrollToSection('qui-sommes-nous')}>Qui Sommes nous?</Link>
          <Link to="/" onClick={() => scrollToSection('nos-societes')}>Nos sociétés</Link>
          <Link to="/" onClick={() => scrollToSection('contact')}>Contact</Link>
        </div>
        <div className="navbar-icon" onClick={toggleSidebar}>
          {sidebarOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      {sidebarOpen && (
        <div className="sidebar">
          <div className="sidebar-close" onClick={toggleSidebar}>
            <FaTimes />
          </div>
          <div className="sidebar-content">
            <h1>JETA GROUPE</h1>
            <div className="sidebar-links">
                <Link to="/" onClick={() => scrollToSection('accueil')}>Accueil</Link>
                <Link to="/" onClick={() => scrollToSection('qui-sommes-nous')}>Qui Sommes nous?</Link>
                <Link to="/" onClick={() => scrollToSection('nos-societes')}>Nos sociétés</Link>
                <Link to="/" onClick={() => scrollToSection('contact')}>Contact</Link>
            </div>
            <p>Nous sommes à votre service.</p>
            <div className="contact-info">
              <h4>Informations de Contact</h4>
              <p><IoTimeOutline /> Lun - Ven: 9:00 - 17:00</p>
              <p><FaPhoneAlt /> +241 456 789 000</p>
              <p><IoIosMail /> info@jetagroupe@gmail.com</p>
            </div>
            <div className="office-address">
              <h4>Adresse du Bureau</h4>
              <p>42 rue de l'Avenir, Odorn</p>
            </div>
            <div className="spacer"></div> 
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
