import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/ArchivedMessages.css';
import axios from 'axios';

const ArchivedMessages = () => {
  const [archivedMessages, setArchivedMessages] = useState([]);

  useEffect(() => {
    fetchArchivedMessages();
  }, []);

  const fetchArchivedMessages = async () => {
    try {
      const response = await axios.get('https://jetagroupe.com/api/contact/archived-messages');
      setArchivedMessages(response.data);
    } catch (error) {
      console.error('Failed to fetch archived messages', error);
    }
  };

  const deleteMessage = async (id) => {
    try {
      await axios.delete(`https://jetagroupe.com/api/contact/message/${id}`);
      setArchivedMessages(archivedMessages.filter(msg => msg._id !== id));
    } catch (error) {
      console.error('Failed to delete message', error);
    }
  };

  return (
    <div className="dashboard-container-contact">
      <AdminNavbar />
      <AdminSidebar />
      <div className="messages-container">
        {archivedMessages.length > 0 ? (
          archivedMessages.map((msg, index) => (
            <div key={msg._id} className="message-card">
              <div className="message-header">
                <h3>Message archivé {index + 1}</h3>
              </div>
              <p><strong>Nom :</strong> {msg.nom}</p>
              <p><strong>Prénom :</strong> {msg.prenom}</p>
              <p><strong>Email :</strong> {msg.email}</p>
              <p><strong>Message :</strong> {msg.message}</p>
              <button 
                className="delete-btn-mes" 
                onClick={() => deleteMessage(msg._id)}
              >
                Supprimer
              </button>
            </div>
          ))
        ) : (
          <p>No archived messages.</p>
        )}
      </div>
    </div>
  );
};

export default ArchivedMessages;
