import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/AdminNavbar.css';
import '../../style/Admin/AdminSideBar.css';
import '../../style/Admin/ContactMessages.css';
import axios from 'axios';

const ContactMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get('https://jetagroupe.com/api/contact/messages'); 
      setMessages(response.data);
    } catch (error) {
      console.error('Failed to fetch messages', error);
    }
  };

  const archiveMessage = async (id) => {
    try {
      await axios.put(`https://jetagroupe.com/api/contact/archive/${id}`);
      setMessages(messages.filter(msg => msg._id !== id));
    } catch (error) {
      console.error('Failed to archive message', error);
    }
  };

  const deleteMessage = async (id) => {
    try {
      await axios.delete(`https://jetagroupe.com/api/contact/message/${id}`);
      setMessages(messages.filter(msg => msg._id !== id));
    } catch (error) {
      console.error('Failed to delete message', error);
    }
  };

  return (
    <div className="dashboard-container-contact">
      <AdminNavbar />
      <AdminSidebar />
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div key={msg._id} className="message-card">
            <div className="message-header">
              <h3>Message {index + 1}</h3>
              <span className="close-btn" onClick={() => archiveMessage(msg._id)}>&times;</span>
            </div>
            <p><strong>Nom :</strong> {msg.nom}</p>
            <p><strong>Prénom :</strong> {msg.prenom}</p>
            <p><strong>Email :</strong> {msg.email}</p>
            <p><strong>Message :</strong> {msg.message}</p>
            <button 
              className="delete-btn-mes" 
              onClick={() => deleteMessage(msg._id)}
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactMessages;
