import React, { useState } from 'react';
import { VscMail } from 'react-icons/vsc';
import { FaPhoneAlt } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import { RiFacebookCircleLine } from "react-icons/ri";
import { RxInstagramLogo } from "react-icons/rx";
import { TfiTwitter } from "react-icons/tfi";
import '../style/Footer.css';
import logo from '../assets/images/logotransp.png'; // Import the logo

const Footer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Veuillez entrer une adresse email valide.');
      return;
    }

    setError(''); // Clear any previous error messages
    console.log(`Subscribing with email: ${email}`); // Log the email being sent
    try {
      const response = await fetch('https://jetagroupe.com/api/subscribe/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      console.log('Response:', data); // Log the response
      if (response.ok) {
        alert('Abonnement réussi !');
        setEmail(''); // Reset email state
      } else {
        alert('Échec de l\'abonnement. Veuillez réessayer plus tard.');
      }
    } catch (error) {
      console.error('Échec de l\'abonnement :', error);
      alert('Échec de l\'abonnement. Veuillez réessayer plus tard.');
    }
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-left">
          <img src={logo} alt="Logo" className="footer-logo" /> {/* Add the logo image */}
          <p>Jeta Groupe, votre allié dans le monde des affaires. Transformez vos idées en réalité avec nous.</p>
          <div className="contact-section">
            <h3>Contactez-nous</h3>
            <p><VscMail className="icon" /> info@jetagroupe@gmail.com</p>
            <p><FaPhoneAlt className="icon" /> +416 22 44 88 10</p>
            <h3>Adresse</h3>
            <p><FiMapPin className="icon" /> 48 rue librevillle, Gabon</p>
          </div>
        </div>
        <div className="footer-middle">
          <h3>S'abonner à la Newsletter</h3>
          <p>Abonnez-vous à notre newsletter pour ne rien manquer et soyez les premiers à découvrir nos nouvelles stratégies.</p>
          <form onSubmit={handleSubscribe}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="custom-btn">S'inscrire</button>
          </form>
          <div className="map-container">
            <iframe
              title="Google Maps Location of Jeta Groupe"
              width="100%"
              height="250"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3989.681360731869!2d9.399272000000002!3d0.47474299999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwMjgnMjkuMSJOIDnCsDIzJzU3LjQiRQ!5e0!3m2!1sfr!2sga!4v1721983105223!5m2!1sfr!2sga"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="footer-right">
          <h3>Pages</h3>
          <ul>
            <li><a href="https://www.example.com">AFRICAN FRET & SERVICES</a></li>
            <li><a href="https://www.example.com">AFRICAN TERMINALES TRANSPORT</a></li>
            <li><a href="https://www.example.com">BLOUM ENERGY</a></li>
            <li><a href="https://www.example.com">LE ROI DES CHANTIERS</a></li>
            <li><a href="https://www.example.com">PREMIUM MOTORS</a></li>
            <li><a href="https://www.example.com">SABUREAUTEL</a></li>
            <li><a href="https://jetaengineering.com/">JETA ENGINEERING</a></li>
            <li><a href="https://www.example.com">AFRICA DISTRIBUTION STATION &CORPORATION</a></li>
          </ul>
        </div>
      </footer>
      <div className="footer-social">
        <div className="social-icons">
          <RiFacebookCircleLine className="icon1" />
          <RxInstagramLogo className="icon1" />
          <TfiTwitter className="icon1" />
        </div>
        <div className="footer-text">
          JETAGROUPE © 2024 Tous droits réservés | Conçu par les développeurs de Jeta Groupe
        </div>
      </div>
    </>
  );
};

export default Footer;
