import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/JobOffers.css';

const JobOffers = () => {
  const [jobOffers, setJobOffers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchJobOffers();
  }, []);

  const fetchJobOffers = async () => {
    try {
      const response = await fetch('https://jetagroupe.com/api/job-offers'); // URL de votre API
      if (!response.ok) {
        throw new Error('Erreur de réseau');
      }
      const data = await response.json();
      setJobOffers(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des offres d\'emploi :', error);
    }
  };

  const handleApplyClick = (jobId) => {
    navigate(`/job-application-form/${jobId}`);
  };

  return (
    <div className="job-listings">
      <Navbar />
      <div className="main-content-container">
        <div className="main-content">
          <h1 className="page-title">Nos Offres de travail</h1>
          <div className="listings-container">
            {jobOffers.length > 0 ? (
              jobOffers.map((offer) => (
                <div className="listing-card" key={offer._id}>
                  <h2 className="listing-title">{offer.jobTitle}</h2>
                  <div className="listing-details">
                    <p><strong>Postes vacants :</strong> {offer.vacancies} postes ouverts</p>
                    <p><strong>Description :</strong> {offer.description}</p>
                    <p><strong>Compétences :</strong> {offer.skills}</p>
                    <p><strong>Expérience :</strong> {offer.experience}</p>
                    <p><strong>Date d'expiration :</strong> {new Date(offer.expirationDate).toLocaleDateString()}</p>
                  </div>
                  <button className="apply-btn" onClick={() => handleApplyClick(offer._id)}>Postuler maintenant</button>
                </div>
              ))
            ) : (
              <p>Aucune offre d'emploi disponible pour le moment.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JobOffers;